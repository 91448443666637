<div class="wrapper">
    <div class="container-header">
        <rds-header
            searchPosition="none"
            minimalMargins
            searchPlaceholder="Search..."
            (logoTriggered)="onLogoTriggered()"
        >
            <rds-divider rds-header-start-divider></rds-divider>
            <rds-header-app-name
                >Digitalization of the Protocol</rds-header-app-name
            >

            <rds-header-navigation-primary>
                <rds-header-link (toggle)="externalLinkClick(rocheChatLink)">
                    RocheChat
                </rds-header-link>
                <rds-header-link (toggle)="externalLinkClick(helpLink)">
                    Help
                </rds-header-link>
                <rds-header-link (toggle)="externalLinkClick(gSiteLink)">
                    gSite
                </rds-header-link>
                <app-product-selector
                    currentProduct="SIL"
                    [productConfig]="appConfig"
                    [userGroups]="relevantGroups"
                ></app-product-selector>
            </rds-header-navigation-primary>
            <rds-divider rds-header-end-divider></rds-divider>
            <rds-header-actions>
                <!-- <div *ngIf="version">{{ version }}</div>
                <div *ngIf="!version">
                    <button rds-icon-button size="m">
                        <rds-icon
                            icon="building"
                            namespace="outlined"
                        ></rds-icon>
                    </button>
                </div> -->
                <rds-avatar
                    rds-dropdown-toggle-button
                    [rdsMenuTriggerFor]="rdsMenu"
                    #trigger="rdsMenuTrigger"
                >
                    <span rds-avatar-initials>{{ avatarInitials }}</span>
                </rds-avatar>
                <rds-menu #rdsMenu="rdsMenu" xPosition="before"
                    ><div rds-menu-header>ACCOUNT</div>
                    <button rds-menu-item (click)="logout()">Logout</button>
                </rds-menu>
            </rds-header-actions>
        </rds-header>
    </div>
    <div class="container-content bg-warm-blue-gradient-bottom-right">
        <!-- <div class="container-side">
            <rds-sidenav
                class="container-sidenav"
                isMinimal
                [isExpandable]="false"
            >
                <a rds-sidenav-menuitem>
                    <rds-icon namespace="outlined" icon="search"></rds-icon>
                    Search Protocols
                </a>
                <a rds-sidenav-menuitem>
                    <rds-icon namespace="outlined" icon="table_view"></rds-icon>
                    STUDI Insights
                </a>

                <rds-sidenav-group> </rds-sidenav-group>
            </rds-sidenav>
        </div> -->
        <div class="container-main">
            <div class="content-block">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
