<div class="product-selector">
    <rds-form-field
        labelPosition="side-left"
        size="s"
        *ngIf="productConfigFiltered && productConfigFiltered.length > 0"
    >
        <rds-select
            #select
            [(value)]="selectedApp"
            (valueChange)="appUpdated()"
        >
            <rds-select-option
                *ngFor="let app of productConfigFiltered"
                [value]="app"
            >
                <rds-icon
                    [icon]="$any(app.iconCode)"
                    namespace="outlined"
                ></rds-icon>
                {{ app.name }}
            </rds-select-option>
        </rds-select>
    </rds-form-field>
</div>
