import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { RdsIconName } from '@rds/angular-components';

import { GlobalSuiteConfig } from 'src/app/model/userIdentity.model';

@Component({
    selector: 'app-product-selector',
    templateUrl: './product-selector.component.html',
    styleUrls: ['./product-selector.component.scss'],
})
export class ProductSelectorComponent implements OnChanges {
    @Input() public currentProduct = '';
    @Input() public productConfig: GlobalSuiteConfig[] | null = null;
    public productConfigFiltered: GlobalSuiteConfig[] | null = null;

    public selectedApp: GlobalSuiteConfig | null = null;
    @Input() public userGroups: Array<string> | undefined = [];

    public appUpdated(): void {
        if (this.selectedApp) {
            window.location.replace(this.selectedApp?.redirectUrl);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const copy: GlobalSuiteConfig[] = [];

        this.productConfig?.forEach((row: GlobalSuiteConfig) => {
            row.iconCode = row.iconCode as RdsIconName;
            if (row.code == this.currentProduct) {
                this.selectedApp = row;
            }
            if (row.hasAccess) {
                copy.push(row);
            }
        });

        this.productConfigFiltered = copy;
    }
}
