import { NgModule, inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    RouterModule,
    RouterStateSnapshot,
    Routes,
} from '@angular/router';

import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { ErrorGlobalPageComponent } from './page/error-global-page/error-global-page.component';
import { LoadingPageComponent } from './page/loading-page/loading-page.component';
import { LoginComponent } from './page/login/login.component';
import { OnlyLoggedInGuard } from './utility/guards/logged-in.guard';

export const appRoutes: Routes = [
    { path: '', component: LoadingPageComponent, pathMatch: 'full' },
    { path: 'start', component: LoadingPageComponent, pathMatch: 'full' },
    { path: 'logging-out', component: LoadingPageComponent, pathMatch: 'full' },
    {
        path: 'login',
        component: LoginComponent,
        pathMatch: 'full',
    },
    {
        path: 'entry',
        component: LoginComponent,
        data: { direct: true },
        pathMatch: 'full',
    },
    {
        path: 'app',
        canActivate: [
            (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
                inject(OnlyLoggedInGuard).canActivate(route, state),
        ],
        children: [
            {
                path: 'start',
                redirectTo: 'protocol-search',
            },
            {
                path: 'protocol-search',
                loadChildren: () =>
                    import(
                        './page/protocol-search/protocol-search.module'
                    ).then((m) => m.ProtocolSearchModule),
            },
            {
                path: 'studi-insights',
                loadChildren: () =>
                    import('./page/insights/insights.module').then(
                        (m) => m.InsightsModule
                    ),
            },
        ],
        component: MainLayoutComponent,
    },
    {
        path: 'configuration-failed',
        component: ErrorGlobalPageComponent,
        data: {
            errorSubtitle:
                'Oops, looks like the global configuration was not set correctly. Please fix the global suite configuration and rebuild the application',
            errorTitle: '!',
        },
    },
    {
        path: '**',
        component: ErrorGlobalPageComponent,
        data: { errorSubtitle: 'Page not found', errorTitle: '404' },
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            anchorScrolling: 'enabled',
            useHash: false,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
