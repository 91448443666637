import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';

import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/service/user.service';

@Injectable({
    providedIn: 'root',
})
export class OnlyLoggedInGuard {
    constructor(
        private oAuthService: OAuthService,
        private userService: UserService,
        private router: Router
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<UrlTree | boolean>
        | Promise<UrlTree | boolean>
        | UrlTree
        | boolean {
        if (this.oAuthService.hasValidAccessToken()) {
            return true;
        } else {
            while (route.firstChild) route = route.firstChild;
            console.log(route.data);
            if (route.data['skipLandingPage']) {
                console.log('SCENARIO A');
                window.location.replace(
                    `${
                        import.meta.env.NG_APP_LANDING_PAGE_URL
                    }/login?path=direct&app=SIL&redirect=${encodeURIComponent(
                        window.location.pathname
                    )}`
                );
            } else {
                console.log('SCENARIO B');
                window.location.replace(
                    `${import.meta.env.NG_APP_LANDING_PAGE_URL}/logout`
                );
            }
            // this.router.navigate(['/login'], {
            //     queryParams: { redirect: state.url },
            //     replaceUrl: true,
            // });
            return false;
        }
    }
}
