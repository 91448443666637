import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { OAuthService } from 'angular-oauth2-oidc';
import { el } from 'date-fns/locale';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';

import {
    GlobalSuiteConfig,
    UserCredentials,
    UserIdentity,
} from '../model/userIdentity.model';

@Injectable({ providedIn: 'root' })
export class UserService {
    public _identityEstablished$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    public _pageConfig$: BehaviorSubject<GlobalSuiteConfig[] | null> =
        new BehaviorSubject<GlobalSuiteConfig[] | null>(null);
    public _sessionExpiredCommand$: BehaviorSubject<null> =
        new BehaviorSubject<null>(null);
    public _userCredentials$: BehaviorSubject<UserCredentials | null> =
        new BehaviorSubject<UserCredentials | null>(null);
    public _userIdentity$: BehaviorSubject<UserIdentity | null> =
        new BehaviorSubject<UserIdentity | null>(null);

    protected applicationAccessDenied = false;

    public identityEstablished = this._identityEstablished$.asObservable();
    public sessionExpiredCommand = this._sessionExpiredCommand$.asObservable();
    public userCredentials = this._userCredentials$.asObservable();

    public userIdentity = this._userIdentity$.asObservable();

    constructor(
        private oAuthService: OAuthService,
        private router: Router
    ) {}

    get applicationAccess() {
        return !this.applicationAccessDenied;
    }

    public establishSession(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this.oAuthService.hasValidAccessToken()) {
                const interval = setInterval(() => {
                    if (
                        !this.oAuthService.hasValidAccessToken() &&
                        !this.applicationAccessDenied
                    ) {
                        clearInterval(interval);
                        this.sessionExpiredCommandRequest();
                    }
                }, 5000);
                try {
                    this.instantiate(
                        this.oAuthService.getAccessToken(),
                        this.oAuthService.getIdToken(),
                        this.oAuthService.getAccessTokenExpiration()
                    );
                    resolve(true);
                } catch (ex) {
                    reject(ex);
                }
            }
            reject(false);
        });
    }

    public instantiate(
        accessToken: string,
        idToken: string,
        expires: number
    ): void {
        const aToken = jwt_decode(accessToken) as any;
        const iToken = jwt_decode(idToken) as any;

        const parseStringCnEntry = (groupEntry: string) => {
            if (groupEntry.includes(',') || groupEntry.includes('=')) {
                let found = '';
                const commaSeparated = groupEntry.split(',');
                commaSeparated.forEach((groupProperty: string) => {
                    const equalParsed = groupProperty.split('=');
                    if (equalParsed[0] === 'CN') {
                        found = equalParsed[1];
                    }
                });
                return found;
            } else {
                return groupEntry;
            }
        };

        const parseCnGroups = (groupsArray: string[]): string[] => {
            const parsed: string[] = [];
            if (!groupsArray) {
                return parsed;
            }
            if (!!groupsArray && !Array.isArray(groupsArray)) {
                parsed.push(parseStringCnEntry(groupsArray));
                return parsed;
            }
            groupsArray.forEach((groupEntry: string) => {
                parsed.push(parseStringCnEntry(groupEntry));
            });
            return parsed;
        };

        const userGroups: string[] = aToken.groups
            ? aToken.groups
            : parseCnGroups(aToken.member_of);

        console.log({
            js: import.meta.env.NG_APP_FE_SUITE,
            userGroups,
            va: this._pageConfig$.value,
        });

        let appAccessFound = false;

        if (userGroups && this._pageConfig$.value) {
            for (let i = 0; i < this._pageConfig$.value.length; i++) {
                let foundForEntry = false;
                this._pageConfig$.value[i].requiredGroup.forEach((group) => {
                    if (group.includes('*')) {
                        userGroups.forEach((userGroup) => {
                            console.log({
                                group,
                                userGroup,
                            });
                            const split = group.split('*');
                            if (
                                userGroup.includes(split[0]) &&
                                userGroup.includes(split[1])
                            ) {
                                foundForEntry = true;
                            }
                        });
                    } else {
                        if (userGroups.includes(group)) {
                            foundForEntry = true;
                        }
                    }
                });
                if (foundForEntry) {
                    this._pageConfig$.value[i].hasAccess = true;
                    if (this._pageConfig$.value[i].code === 'SIL') {
                        appAccessFound = true;
                    }
                }
            }
        }
        if (!appAccessFound) {
            this.applicationAccessDenied = true;
        }

        this._identityEstablished$.next(true);

        this._userIdentity$.next({
            email: aToken.email,
            givenName: aToken.givenName,
            lastName: aToken.lastname,
            name: aToken.name,
            sub: aToken.sub,
        });
        this._userCredentials$.next({
            accessToken: accessToken,
            clientId: iToken.aud,
            expiresIn: expires,
            idToken: idToken,
            relevantGroups: userGroups,
        });
    }

    public logout(expired = false, navigate = true, meaningful = false): void {
        sessionStorage.clear();
        this._identityEstablished$.next(false);
        this._userIdentity$.next(null);
        this._userCredentials$.next(null);
        if (navigate) {
            if (expired) {
                sessionStorage.setItem('justExpired', 'true');
            }
            if (meaningful) {
                this.router.navigate(['/']);
                setTimeout(() => {
                    window.location.reload();
                }, 5);
            } else {
                window.location.replace(
                    import.meta.env.NG_APP_LANDING_PAGE_URL + '/login'
                );
            }
        }
    }

    public parseSuiteConfiguration() {
        const pageConfig: GlobalSuiteConfig[] | null = JSON.parse(
            import.meta.env.NG_APP_FE_SUITE
        );
        if (pageConfig) {
            if (pageConfig.length <= 0) {
                throw 'Empty config set';
            }
            for (let i = 0; i < pageConfig.length; i++) {
                if (pageConfig[i].code.length <= 0) {
                    throw 'Config entry is missing app code';
                }
                if (pageConfig[i].redirectUrl.length <= 0) {
                    throw 'Config entry is missing redirect url';
                }
            }
            this._pageConfig$.next(pageConfig);
        } else {
            throw 'Unknown error: Config is missing';
        }
    }

    public sessionExpiredCommandRequest() {
        this._sessionExpiredCommand$.next(null);
    }
}
